import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, } from 'vue-property-decorator';
let addOrg = class addOrg extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.submitloading = false;
    }
    submit() {
        this.submitloading = true;
        this.orgConfigForm.submit().then((res) => {
            if (res.success) {
                this.cancel();
                this.$emit('reload');
                this.orgConfigForm.cForm.resetFields();
            }
        }).finally(() => {
            this.submitloading = false;
        });
    }
    cancel() {
        this.$emit('input', false);
        this.orgConfigForm.cForm.resetFields();
    }
    onValueChange(bl) {
        this.visible = bl;
    }
};
__decorate([
    Prop()
], addOrg.prototype, "value", void 0);
__decorate([
    Ref()
], addOrg.prototype, "orgConfigForm", void 0);
__decorate([
    Watch('value')
], addOrg.prototype, "onValueChange", null);
addOrg = __decorate([
    Component({
        name: 'addOrg',
        components: {
            OrgConfigForm: () => import('./org-config-form.vue'),
        },
    })
], addOrg);
export default addOrg;
